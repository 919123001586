.picture {
  display: flex;
}

.img {
  height: auto;
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
}

.radiusSmall {
  border-radius: 0.25rem;
}

.squared {
  aspect-ratio: 1 / 1;
}
