.link {
  color: var(--link-color);
  font-size: inherit;
  text-decoration: none;
}

.link:visited {
  color: var(--link-color);
}

.linkButton {
  font-family: var(--bs-font-sans-serif);
  font-size: var(--font-size-16);
  color: var(--link-color);
  text-decoration: none;
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--border-radius-4);
  font-weight: 600;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}

.linkButtonPrimary {
  background-color: var(--link-color);
  color: var(--text-color-inverse);
  border: none;
  border: 1px solid transparent;
  transition: background-color 0.2s ease 0s;
}

.linkBoxed {
  padding: var(--spacing-24);
  border-radius: var(--border-radius-8);
  cursor: pointer;
  text-decoration: none;
  color: var(--text-color);
  width: 100%;
  border: 1px solid var(--divider-soft);
  background: var(--background-container);
  position: relative;
}

.linkBoxed:visited {
  color: inherit;
}

.small {
  padding: var(--spacing-6);
  font-size: var(--font-size-14);
}

@media (hover: hover) {
  .link:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
  .linkButton:hover {
    background: var(--gradient);
    filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.3));
  }

  .linkButtonPrimary:hover {
    background-color: var(--link-color-hover);
    filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.1));
  }
  .linkBoxed:hover {
    background: var(--gradient);
    text-decoration: none;
  }
}

@media only screen and (max-width: 1200px) {
  .linkBoxed {
    padding: var(--spacing-32) var(--spacing-32);
  }
  .linkButtonPrimary {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .linkButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .small {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .linkBoxed {
    padding: var(--spacing-16);
    min-width: calc(100% - 48px);
  }

  .linkButton {
    padding: var(--spacing-12) var(--spacing-12);
  }

  .small {
    padding: var(--spacing-6);
    font-size: var(--font-size-14);
  }
}
