.contained {
  width: stretch;
  margin: 0 var(--spacing-24);
  border-color: var(--divider-soft);
}

.full {
  width: stretch;
  margin: 0;
  border-color: var(--divider-soft);
}
