.button {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: var(--spacing-8);
  font-family: var(--bs-font-sans-serif);
  font-size: var(--font-size-16);
  color: var(--text-color);
  text-decoration: none;
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--border-radius-4);
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--divider);
}

.buttonPrimary3D {
  background-color: var(--link-color);
  color: var(--text-color-inverse);
  border: none;
  transition: background-color 0.2s ease 0s;
  box-shadow: 0rem -0.0625rem 0rem 0.0625rem hsla(0, 0%, 0%, 0.33) inset,
    0rem 0rem 0rem 0.0625rem hsla(0, 0%, 0%, 0.2) inset,
    0rem 0.03125rem 0rem 0.09375rem hsla(0, 0%, 100%, 0.25) inset;
}

.buttonPrimary {
  background-color: var(--link-color);
  color: var(--text-color-inverse);
  border: none;
  transition: background-color 0.2s ease 0s;
}

.buttonPrimary3D:active span {
  transform: translate(0, 0.0625rem);
}

.buttonTertiary {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid transparent;
  transition: background-color 0.2s ease 0s;
}

.label {
  line-height: 1;
}

.small {
  padding: var(--spacing-6) var(--spacing-12);
  font-size: var(--font-size-14);
  height: 32px;
}

@media (hover: hover) {
  .button:hover {
    background: var(--gradient);
    filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.3));
  }

  .buttonPrimary:hover {
    background-color: var(--link-color-hover);
    filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.1));
  }

  .buttonPrimary3D:hover {
    background-color: var(--link-color-hover);
    filter: drop-shadow(1px 2px 22px hsla(0, 0%, 100%, 0.065));
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem hsla(0, 0%, 0%, 0.33) inset,
      0rem 0rem 0rem 0.0625rem hsla(0, 0%, 0%, 0.2) inset,
      0rem 0.03125rem 0rem 0.09375rem hsla(0, 0%, 100%, 0.25) inset;
  }
}

.buttonPrimary3D:active {
  box-shadow: -0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.33) inset,
    0.0625rem 0rem 0.0625rem 0rem rgba(26, 26, 26, 0.33) inset,
    0rem 0.125rem 0.0625rem 0rem rgba(26, 26, 26, 0.2) inset;
}

@media only screen and (max-width: 1200px) {
  .buttonPrimary {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 640px) {
  .button {
    padding: var(--spacing-12) var(--spacing-12);
    width: 100%;
  }

  .small {
    padding: var(--spacing-12);
    height: 44px;
    font-size: var(--font-size-14);
  }
}
