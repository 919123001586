.container {
  font-family: var(--bs-font-sans-serif);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 760px;
}

@media only screen and (max-width: 1124px) {
  .container {
    margin: 0 var(--spacing-medium);
  }
}

@media only screen and (max-width: 640px) {
  .container {
    margin: 0 var(--spacing-24);
  }
}
