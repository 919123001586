.container {
  padding: var(--spacing-24);
  border: 1px solid var(--divider-soft);
  border-radius: var(--border-radius-8);
  background-color: var(--background-container);
  display: flex;
  gap: var(--spacing-16);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.highlighted {
  background-color: var(--highlighted-background);
  border: 1px solid var(--highlighted-color);
}

.header {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
}

.title {
  font-size: var(--font-size-20);
  font-weight: bold;
}

.description {
  font-size: var(--font-size-14);
  color: var(--text-color-secondary);
}

.actions {
  padding-top: var(--spacing-24);
}

.icon {
  fill: var(--text-color);
}

@media only screen and (max-width: 640px) {
  .title {
    font-size: var(--font-size-16);
  }
}
