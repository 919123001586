.container {
  width: max-content;
  position: relative;
}

.badge {
  position: absolute;
  top: -8px;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 2px var(--background-color-inverse);
  }

  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 4px var(--background-color-inverse);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 2px var(--background-color-inverse);
  }
}
