.footerContainer{
    padding: var(--spacing-small) 0;
    border-top: 1px solid var(--divider-soft);
}

.footerContent{
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
}

.footerParagraph{
margin: 0;
line-height: 1.5;
}


.footerSubParagraph{
    margin: 0;
    line-height: 1.5;
    color: var(--text-color-secondary);
    font-size: var(--font-size-14);
}

@media only screen and (max-width: 1200px) {
    .footerContent{
        margin: 0 var(--spacing-medium);
      }
}

@media only screen and (max-width: 640px) {
    .footerContent{
      margin: 0 var(--spacing-24);
    }
}