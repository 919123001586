.appBackground {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: -100;
  top: 0px;
  bottom: 0px;
  background: radial-gradient(
      ellipse at top left,
      var(--gradient-tint-02),
      transparent
    ),
    radial-gradient(
      ellipse at bottom right,
      var(--gradient-tint-03),
      transparent
    ),
    radial-gradient(ellipse at bottom, var(--gradient-tint-03), transparent),
    radial-gradient(
      ellipse at bottom left,
      var(--gradient-tint-03),
      transparent
    ),
    radial-gradient(
      ellipse at top right,
      var(--gradient-tint-04),
      var(--gradient-tint-01),
      transparent
    );
  z-index: -2;
}
