.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.anchor {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding-top: 100px;
  margin-top: -100px;
}

.title {
  font-size: var(--font-size-16);
  color: var(--text-color-secondary);
  margin: 0;
}

.hasLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
