.appBackground {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: -100;
  top: 0px;
  bottom: 0px;
  background: radial-gradient(
      ellipse at top left,
      var(--gradient-tint-02),
      transparent
    ),
    radial-gradient(
      ellipse at bottom right,
      var(--gradient-tint-03),
      transparent
    ),
    radial-gradient(ellipse at bottom, var(--gradient-tint-03), transparent),
    radial-gradient(
      ellipse at bottom left,
      var(--gradient-tint-03),
      transparent
    ),
    radial-gradient(
      ellipse at top right,
      var(--gradient-tint-04),
      var(--gradient-tint-01),
      transparent
    );
  z-index: -2;
}

.ambientButton {
  padding: var(--spacing-8) var(--spacing-16) var(--spacing-8) 40px;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  color: var(--text-color);
  cursor: pointer;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--divider);
  display: inline-flex;
  align-items: center;
  height: 32px;
  width: max-content;
  position: relative;
}

.ambientButton[aria-pressed="true"] {
  padding-right: 48px;
}

.ambientButton:hover {
  background: var(--gradient);
  filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.2));
}

.ambientColorPicker {
  border-radius: var(--border-radius-full);
  border: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  border: 1px solid var(--divider);
  pointer-events: none;
}

.ambientColorPickerUno {
  right: 20px;
}
.ambientColorPickerDos {
  right: 12px;
}

@media only screen and (max-width: 640px) {
  .ambientContainer {
    display: none;
  }
}
