.headerContainer {
  position: sticky;
  top: 0;
  padding: var(--spacing-16) 0;
  backdrop-filter: blur(10px);
  z-index: 2;
  border-bottom: 1px solid var(--divider-soft);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(0px 0px 10px var(--background-color));
}

.mobilePrefix {
  padding: var(--spacing-16) 0 0 6px;
}

.icon {
  fill: var(--text-color);
}

.headerLogoContainer {
  display: flex;
  gap: var(--spacing-16);
  align-items: center;
  text-decoration: none;
}

.headerLogoContainer > * img {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--divider);
}

.headerNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerUl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  gap: var(--spacing-24);
  margin: 0;
  padding: 0;
  filter: drop-shadow(0px 0px 8px var(--background-color));
}

.headerUl > li {
  display: flex;
}

.headerLink {
  font-family: var(--bs-font-sans-serif);
  font-size: var(--font-size-14);
  color: var(--text-color);
  text-decoration: none;
  padding: var(--spacing-6);
  border-radius: var(--border-radius-4);
  font-weight: 600;
  border: 1px solid transparent;
  cursor: pointer;
}

.active {
  border: 1px solid var(--text-color);
}

.headerLink.active {
  border: 1px solid var(--divider);
}

.headerLogo {
  text-decoration: none;
  color: var(--text-color);
  font-size: var(--font-size-16);
  margin: 0;
  filter: drop-shadow(0px 0px 8px var(--background-color));
}

.responsiveButton {
  display: none;
}

@media (hover: hover) {
  .headerLink:hover {
    background: var(--gradient);
  }
}

@media only screen and (max-width: 1200px) {
  .headerContent {
    filter: none;
  }
}

@media only screen and (max-width: 640px) {
  .headerLogoContainer > img {
    width: 32px;
    height: 32px;
  }

  .headerContent {
    justify-content: space-between;
    filter: none;
  }

  .headerLogo {
    filter: none;
  }

  .headerNav {
    display: flex;
    justify-content: space-between;
  }

  .headerUl {
    display: none;
  }

  .responsiveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    text-decoration: none;
    padding: var(--spacing-6);
    border-radius: var(--border-radius-4);
    font-weight: 600;
    border: none;
    cursor: pointer;
    background-color: transparent;
    height: 44px;
    width: 44px;
  }
  @media (hover: hover) {
    .responsiveButton:hover {
      background: var(--gradient);
    }
  }
}
