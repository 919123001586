.container {
  border-radius: var(--border-radius-full);
  border: 1px solid var(--divider);
  display: inline-flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  width: max-content;
  position: relative;
}

.select {
  padding: 0 40px;
  height: 100%;
  cursor: pointer;
  background: none;
  width: 100%;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: var(--text-color);
}

.select:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

@media (hover: hover) {
  .container:hover {
    background: var(--gradient);
    filter: drop-shadow(1px 2px 22px hsl(0, 0%, 100%, 0.3));
  }
}

@media only screen and (max-width: 640px) {
  .container {
    height: 44px;
  }
}
