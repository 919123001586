.listUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.li {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.listLi {
  padding: var(--spacing-8) 0;
  border-top: 1px dotted var(--divider);
}

.listLi:last-child {
  border-bottom: 1px dotted var(--divider);
}

.listLi * {
  text-decoration: none;
}

.listHeading {
  text-decoration: none;
  font-size: var(--font-size-20);
}

.listDescription {
  font-size: var(--font-size-14);
  color: var(--text-color-secondary);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-8);
}

.listDetail {
  font-size: var(--font-size-14);
  line-height: 1.5em;
  color: var(--text-color-secondary);
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.externalIcon {
  position: absolute;
  right: 24px;
  top: 24px;
}

.image {
  object-fit: cover;
  border-radius: var(--border-radius-4);
  aspect-ratio: 16/9;
  max-width: 100%;
}

@media only screen and (max-width: 640px) {
  .listHeading {
    text-decoration: none;
    font-size: var(--font-size-16);
  }
}
