.container {
  display: flex;
}

.spacing2 {
  gap: var(--spacing-2);
}
.spacing4 {
  gap: var(--spacing-4);
}
.spacing6 {
  gap: var(--spacing-6);
}
.spacing8 {
  gap: var(--spacing-8);
}
.spacing12 {
  gap: var(--spacing-12);
}
.spacing14 {
  gap: var(--spacing-14);
}
.spacing16 {
  gap: var(--spacing-16);
}
.spacing24 {
  gap: var(--spacing-24);
}
.spacing32 {
  gap: var(--spacing-32);
}
.spacingXSmall {
  gap: var(--spacing-xsmall);
}
.spacingSmall {
  gap: var(--spacing-small);
}
.spacingMedium {
  gap: var(--spacing-medium);
}
.spacingLarge {
  gap: var(--spacing-large);
}
.spacingXLarge {
  gap: var(--spacing-xlarge);
}
