.heroContent {
  padding: var(--spacing-medium) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
}

.heroSectionTitle {
  font-size: var(--font-size-16);
  color: var(--text-color-secondary);
  margin: 0;
}

.heroReactLink {
  display: flex;
  text-decoration: none;
}

.heroTitle {
  font-size: var(--font-size-48);
  margin: 0;
  max-width: 24ch;
  line-height: 1.125em;
  text-wrap: balance;
}

.heroDescription {
  font-size: var(--font-size-20);
  margin: 0;
  max-width: 44ch;
  color: var(--text-color-secondary);
  line-height: 1.5;
  text-wrap: balance;
}

.heroButtonsLayout {
  display: flex;
  padding-top: var(--spacing-24);
  gap: var(--spacing-12);
}

@media only screen and (max-width: 1200px) {
  .heroReactLink {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .heroContainer {
    padding: var(--spacing-medium) 0;
  }

  .heroTitle {
    font-size: var(--font-size-32);
  }

  .heroDescription {
    font-size: var(--font-size-16);
  }

  .heroButtonsLayout {
    flex-direction: column;
  }
}
