.chipContainer {
  width: max-content;
  padding: var(--spacing-2) var(--spacing-8);
  border-radius: var(--border-radius-full);
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  background-color: var(--background-color-inverse);
}

.chipContainer * {
  font-size: var(--font-size-12);
  line-height: 1.5;
  font-weight: 600;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 4px;
}
