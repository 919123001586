.content {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-8);
  padding: var(--spacing-24) 0;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 640px) {
  .content {
    margin: 0;
    padding: 0;
    height: 0;
  }

  .content div:last-child {
    visibility: hidden;
  }
}
