/* inter-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/inter-v12-latin-300.woff2") format("woff2");

  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* inter-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/inter-v12-latin-regular.woff2") format("woff2");

  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* inter-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/inter-v12-latin-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
  /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-display: swap;
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Lora-VariableFont_wght.ttf") format("ttf");
}

:root {
  --background-color: #fafafa;
  --background-container: transparent;
  --background-color-inverse: #1a1a1a10;
  --text-color: #121416d8;
  --button-text: #fff;

  /* Type------------------------------ */
  --bs-font-sans-serif: "Inter", sans-serif;
  --bs-font-serif-display: "Lora", serif;
  --font-serif: "Source Serif Pro", serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-32: 2rem;
  --font-size-40: 2.5rem;
  --font-size-48: 3rem;
  --font-size-64: 4rem;
  /* spacing------------------------------ */
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-6: 0.375rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-14: 0.875rem;
  --spacing-16: 1rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-xsmall: 1.5rem;
  --spacing-small: 3rem;
  --spacing-medium: 6rem;
  --spacing-large: 12rem;
  --spacing-xlarge: 16rem;
  --transparent: transparent;

  /* border radius------------------------------ */

  --border-radius-4: 0.25rem;
  --border-radius-8: 0.5rem;
  --border-radius-12: 0.75rem;
  --border-radius-16: 1rem;
  --border-radius-full: 9999px;

  --preColor: rgba(192, 192, 192, 0.109);
}

@media (prefers-color-scheme: light) {
  :root {
    --background-color: #fafafa;
    --background-color-inverse: #1a1a1a10;
    --background-container: transparent;
    --background-hero: linear-gradient(45deg, #dfdeeb, transparent);
    --text-color: #333333;
    --text-color-secondary: #5d5d5d;
    --text-color-inverse: #f1f1f1;
    --link-color: #543fd7;
    --link-color-hover: #4634be;
    --link-color-focus: #533fd78c;
    --success-color: #00a36c;

    --gradient-none: linear-gradient(
      to left,
      rgba(48, 34, 139, 0),
      rgba(0, 195, 255, 0)
    );

    --gradient: linear-gradient(
      to left,
      rgba(48, 34, 139, 0.09),
      rgba(0, 195, 255, 0.12)
    );

    --gradient-soft: linear-gradient(
      to left,
      rgba(48, 34, 139, 0.06),
      rgba(0, 195, 255, 0.08)
    );

    --gradient-tint-01: rgba(48, 34, 139, 0.4);
    --gradient-tint-02: rgba(0, 195, 255, 0.01);
    --gradient-tint-03: hsl(0, 0%, 98%, 1);
    --gradient-tint-04: hsla(298, 100%, 50%, 0.5);
    --button-hover-color: rgba(0, 0, 0, 0.39);

    --active-color: #533fd742;
    --divider: #5d5d5d5e;
    --divider-soft: #00000010;
    --highlighted-background: #b1a5ff28;
    --highlighted-color: #533fd758;
    --display-icon-light: none;
    --display-icon-dark: block;
    --value-color: MediumVioletRed;
    --alternate-background-color: rgba(185, 175, 253, 0.034);
    --alternate-border-color: rgba(48, 34, 139, 0.4);

    --kofi-color: #f6f5fe;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1a1a1a;
    --background-color-inverse: #fafafa10;
    --background-container: rgba(255, 255, 255, 0.02);
    --background-hero: linear-gradient(45deg, #201f46, transparent);
    --text-color: #f1f1f1;
    --text-color-secondary: #c3c3c3;
    --text-color-inverse: #333333;
    --link-color: #d7d2ff;
    --link-color-hover: #c9c4f2;
    --link-color-focus: #cdc5ff;
    --success-color: #afe1af;

    --gradient-none: linear-gradient(
      to left,
      rgba(102, 90, 177, 0),
      rgba(0, 195, 255, 0)
    );

    --gradient: linear-gradient(
      to left,
      rgba(102, 90, 177, 0.26),
      rgba(0, 195, 255, 0.13)
    );

    --gradient-soft: linear-gradient(
      to left,
      rgba(102, 90, 177, 0.06),
      rgba(0, 195, 255, 0.04)
    );

    --gradient-tint-01: rgb(48, 34, 139);
    --gradient-tint-02: rgba(0, 195, 255, 0.01);
    --gradient-tint-03: hsl(0, 0%, 10%, 1);
    --gradient-tint-04: hsla(298, 100%, 50%, 0.1);

    --button-hover-color: #ffffff69;
    --active-color: #68686869;
    --divider: #ffffff50;
    --divider-soft: #ffffff10;
    --highlighted-background: #0e0a2845;
    --highlighted-color: #c1b7ff50;
    --display-icon-light: block;
    --display-icon-dark: none;
    --value-color: LightPink;
    --alternate-background-color: rgba(24, 24, 29, 0.164);
    --alternate-border-color: rgba(184, 170, 255, 0.4);

    --kofi-color: rgb(39, 35, 37);
  }
}

body.light {
  --background-color: #fafafa;
  --background-color-inverse: #1a1a1a10;
  --background-container: transparent;
  --background-hero: linear-gradient(45deg, #dfdeeb, transparent);
  --text-color: #333333;
  --text-color-secondary: #5d5d5d;
  --text-color-inverse: #f1f1f1;
  --link-color: #543fd7;
  --link-color-hover: #4634be;
  --link-color-focus: #533fd78c;
  --success-color: #00a36c;

  --gradient-none: linear-gradient(
    to left,
    rgba(48, 34, 139, 0),
    rgba(0, 195, 255, 0)
  );

  --gradient: linear-gradient(
    to left,
    rgba(48, 34, 139, 0.09),
    rgba(0, 195, 255, 0.12)
  );

  --gradient-soft: linear-gradient(
    to left,
    rgba(48, 34, 139, 0.06),
    rgba(0, 195, 255, 0.08)
  );

  --gradient-tint-01: rgba(48, 34, 139, 0.4);
  --gradient-tint-02: rgba(0, 195, 255, 0.01);
  --gradient-tint-03: hsl(0, 0%, 98%, 1);
  --gradient-tint-04: hsla(298, 100%, 50%, 0.5);
  --button-hover-color: rgba(0, 0, 0, 0.39);

  --active-color: #533fd742;
  --divider: #5d5d5d5e;
  --divider-soft: #00000010;
  --highlighted-background: #b1a5ff28;
  --highlighted-color: #533fd758;
  --display-icon-light: none;
  --display-icon-dark: block;
  --value-color: MediumVioletRed;
  --alternate-background-color: rgba(185, 175, 253, 0.034);
  --alternate-border-color: rgba(48, 34, 139, 0.4);

  --kofi-color: #f6f5fe;
}

body.dark {
  --background-color: #1a1a1a;
  --background-color-inverse: #fafafa10;
  --background-container: rgba(255, 255, 255, 0.02);
  --background-hero: linear-gradient(45deg, #201f46, transparent);
  --text-color: #f1f1f1;
  --text-color-secondary: #c3c3c3;
  --text-color-inverse: #333333;
  --link-color: #d7d2ff;
  --link-color-hover: #c9c4f2;
  --link-color-focus: #cdc5ff;
  --success-color: #afe1af;

  --gradient-none: linear-gradient(
    to left,
    rgba(102, 90, 177, 0),
    rgba(0, 195, 255, 0)
  );

  --gradient: linear-gradient(
    to left,
    rgba(102, 90, 177, 0.26),
    rgba(0, 195, 255, 0.13)
  );

  --gradient-soft: linear-gradient(
    to left,
    rgba(102, 90, 177, 0.06),
    rgba(0, 195, 255, 0.04)
  );

  --gradient-tint-01: rgb(48, 34, 139);
  --gradient-tint-02: rgba(0, 195, 255, 0.01);
  --gradient-tint-03: hsl(0, 0%, 10%, 1);
  --gradient-tint-04: hsla(298, 100%, 50%, 0.1);

  --button-hover-color: #ffffff69;
  --active-color: #68686869;
  --divider: #ffffff50;
  --divider-soft: #ffffff10;
  --highlighted-background: #0e0a2845;
  --highlighted-color: #c1b7ff50;
  --display-icon-light: block;
  --display-icon-dark: none;
  --value-color: LightPink;
  --alternate-background-color: rgba(24, 24, 29, 0.164);
  --alternate-border-color: rgba(184, 170, 255, 0.4);

  --kofi-color: rgb(39, 35, 37);
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  color: var(--text-color);
}

body * {
  box-sizing: border-box;
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  margin: 0;
}

sup {
  line-height: 0;
}

.language-html {
  background: transparent !important;
}

.language-css {
  background: transparent !important;
}

.language-json {
  background: transparent !important;
}

.language-javascript {
  background: transparent !important;
}
