.icon {
  width: 16px;
  fill: currentColor;
}

.suffix {
  position: absolute;
  right: 12px;
  pointer-events: none;
  font-size: 16px;
}

.prefix {
  position: absolute;
  left: 12px;
  pointer-events: none;
  font-size: 16px;
}
