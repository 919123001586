.circleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
}

.circleLight {
  background-color: #ffffffaa;
}

.circleDark {
  background-color: #000000aa;
}
