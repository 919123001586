

  .listUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16)
  }

  @media only screen and (max-width: 640px) {
    .listUl {
      gap: var(--spacing-8)
    }
  }

