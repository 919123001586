.appContainer {
  font-family: var(--bs-font-sans-serif);
  margin: var(--spacing-32) 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 407px);
}

.gutterM {
  gap: var(--spacing-small);
}

.gutterD {
  gap: var(--spacing-medium);
}

.home {
  gap: var(--spacing-medium);
}

@media only screen and (max-width: 1200px) {
  .appContainer {
    margin: var(--spacing-medium) 0;
  }

  .home {
    gap: var(--spacing-medium);
  }
}

@media only screen and (max-width: 640px) {
  .appContainer {
    margin: var(--spacing-small) 0;
  }

  .home {
    gap: var(--spacing-small);
  }
}
