.container {
  position: absolute;
  padding: var(--spacing-24) 0;
  width: 100%;
  z-index: 2;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--divider);
  filter: drop-shadow(0px 0px 10px var(--background-color));
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.navRow {
  padding: var(--spacing-16) var(--spacing-24);
  cursor: pointer;
  width: 100%;
  display: flex;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
  align-items: baseline;
  justify-content: space-between;
}

.navText {
  margin-left: 16px;
}

.active::before {
  position: absolute;
  content: "❙";
  color: var(--link-color);
  display: flex;
  align-items: center;
}

.divider {
  width: stretch;
  margin: 0 var(--spacing-24);
  border-color: var(--divider-soft);
}

.themeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.themeSelect {
  list-style: none;
  padding: 0 var(--spacing-24);
  margin: 0;
  margin-bottom: var(--spacing-8);
}

.themeLabel {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
  margin-left: 12px;
}

@media (hover: hover) {
  .navRow:hover {
    background: var(--gradient);
  }
}

@media only screen and (min-width: 640px) {
  .container {
    display: none;
  }
  .backdrop {
    height: 100vh;
    width: 100%;
    color: black;
  }
}
